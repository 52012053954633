import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import dayjs from './dependencies/dayjs';
import Header from './Header';
import { getWhatballer, getWhatballerAttempt, getWhatballerHint } from './http/whatballer.http';
import { getUserId, setUserId } from './local/user.local';
import InputCharacters from './InputCharacters';
import { getLocalToken } from './local/token.local';
import { apiStatus } from './http/Login';
import { difficulty, wordSpaceCharacter } from './constants/characters.constants';

function App() {
  const [inputCharacters, setInputCharacters] = useState([]);
  const inputRefs = useRef([]); // Create an array of refs for each input field
  const [playerData, setPlayerData] = useState(null);
  const [error, setError] = useState(null);
  const [visibleIndexes, setVisibleIndexes] = useState([]);
  const [hintsAvailable, setHintsAvailable] = useState([]);
  const [date] = useState(dayjs().format('YYYY-MM-DD'));
  const [attempts, setAttempts] = useState([]);
  const [firstLetterUsed, setFirstLetterUsed] = useState(false);
  const [revealed, setRevealed] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const localAuth = getLocalToken();
    if (!localAuth) {
      return false;
    }

    return apiStatus(localAuth).then(res => {
      if (res.valid) {
        return true;
      }

      return false;
    }).catch(e => {
      console.error(e)
      return false;
    })
  });

  const handleCorrectAnswer = () => {
    setIsCorrect(true);
  };

  const handleObfuscation = (chars) => {
    return chars.map(char => ({ ...char, value: char.correct === true || char.reveal === true || char.hint === true || char.value === ' ' ? char.value : '_' }))
  }

  // On POST, detect if last possible attempt.
  // If last possible attempt and not correct, reveal the answer / missing characters (as red)
  // On GET, check for attempts, if last possible attempt then do the same

  const handleReveal = () => {
    setRevealed(true)
    getWhatballerT({ reveal: true }).catch(e => {
      console.error(e, 'error revealing')
    });
  }
  
  // Show previous would be a good feature, perhaps a dropdown arrow that shows your prev answers
  const handleHintClick = (target) => {
    if (isCorrect) {
      return;
    }

    getWhatballerHint({ difficulty, date, type: target.innerText })
      .then(res => {
        // console.log(res, 'hints');
        if (res.data.hint.type === 'FIRST LETTER') {
          setFirstLetterUsed(true);
          setInputCharacters((prevInputCharacters) => {
            const newInputCharacters = prevInputCharacters.map((char, index) => {
              if (char.correct) {
                return char;
              }

              const hint = res.data.hint.indexesOfFirstLetters.find(indexes => indexes.index === index);

              if (hint) {
                return { value: hint.value, correct: true, hint: true }
              }
              
              return char;
            })

            return newInputCharacters
          });
        }

        // if (res.data.hint.type === 'BIRTH PLACE') {
          // Is there an API that will take a place and give you the country?
          // setBirthPlaceUsed: alter colour and strike-through, make non-clickable
          // Show modal that disappears after a few seconds, perhaps button can be repeatedly clickable
        // }
        // Go & get the hints available, and now it should show one with a strike-through
      }).catch(e => {
        console.log(e, 'hint error');
      });
  }

  const handleDateFormatting = (yearRange) => {
    // console.log(yearRange)
    const y = yearRange.replaceAll('–', '-')
    // Check if there's a dash, indicating a range of years
    if (y.includes('-')) {
      const [startYear, endYear] = y.split('-');

      // Check for prefixes "20" or "19" and remove them for both start and end years
      // const formattedStartYear = startYear.startsWith('20') ? startYear.slice(2) : startYear.startsWith('19') ? startYear.slice(2) : startYear;
      const formattedEndYear = endYear.startsWith('20') ? endYear.slice(2) : endYear.startsWith('19') ? endYear.slice(2) : endYear;

      // Return the formatted range
      return `${startYear}-${formattedEndYear}`;
    }

    // Return the input as is if there's no dash (i.e., a singular year)
    return y;
  }

  const handleShare = async () => {
    if (navigator.share) {
      try {
        const attempt = attempts?.[0].attempt;

        if (attempt.some(a => a.correct !== true)) {
          return;
        }

        // const balls = attempts.map(_a => String.fromCodePoint(0x26BD));

        // console.log(attempt, 'latest attempt')
        const iconsToShare = attempt.map(a => {
          if (a.hint === true) {
            return String.fromCodePoint(0x1F7E6);
          }

          if (a.value === ' ') {
            return '    '
          }

          return String.fromCodePoint(0x1F7E9);
        }).join('')

        const value = attempts?.length > 1 ? 'beat' : 'match';

        await navigator.share({
          title: ``,
          text: `Got it in ${attempts.length}!\n\n${iconsToShare}\n\nCan you ${value} that?\n\nwhatballer.com`,
        });
        console.log('Content shared successfully');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  }

  /**
   * When reveal is passed the reveal is not persisted
   */
  const getWhatballerT = async (extraArgs = {}) => {
    getWhatballer({ difficulty, date, ...extraArgs })
      .then(({ data: whatballerData }) => {
        setPlayerData(whatballerData);
        setInputCharacters(whatballerData.data.nameResponse);
        setHintsAvailable(whatballerData.data.hints?.filter(hint => hint.available) || []);

        return getWhatballerAttempt({ difficulty, date })
          .then(({ data: whatballerAttempt }) => {
            if (whatballerAttempt.results?.length) {
              const obfuscatedAttempt = handleObfuscation(whatballerAttempt.results[0].attempt)
              setInputCharacters(obfuscatedAttempt);
              setAttempts(whatballerAttempt.results);
              setFirstLetterUsed(whatballerAttempt.results.some(result => result.attempt.some(a => a.hint === true)));

              setRevealed(whatballerAttempt.results.some(result => result.attempt.some(a => a.reveal === true)))

              if (!whatballerAttempt.correctAttempt && inputRefs.current[0]) {
                const indexToFocus = obfuscatedAttempt.findIndex(char => char.correct === false && char.value !== ' ');
                inputRefs.current[indexToFocus].focus();
              }

            } else {
              setIsCorrect(false);
              if (!whatballerAttempt.correctAttempt && inputRefs.current[0]) {
                inputRefs.current[0].focus(); // Focus on the first input field after mount
              }
            }

            if (whatballerAttempt.correctAttempt) {
              handleCorrectAnswer()
              // Should strikethrough the hint without teh diff colour if it's not used
              // Or should remove the hovers and buttons
              // Set the chars as gold
            }

            const timeouts = [];

            for (let i = 0; i < 35; i++) {
              const timeout = setTimeout(() => {
                setVisibleIndexes((prevIndexes) => [...prevIndexes, i]);
              }, i * 50); // Delay each input's appearance by 500ms
              timeouts.push(timeout);
            }
        
            // Cleanup timeouts on component unmount
            return () => {
              timeouts.forEach((timeout) => clearTimeout(timeout));
            };
          })
      })
  }

  useEffect(() => {
    const uuid = getUserId();

    if (!Boolean(uuid) || uuid === 'undefined' || uuid === 'null') {
      const firstUUID = uuidv4()
      setUserId(firstUUID);
    }

    getWhatballerT().catch((err) => {
      setError(err.message); // Handle error if any
      console.error("Error fetching data:", err);
    });
  }, []); // Empty dependency array to trigger request only on component mount
  
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 640px)'); // Tailwind's sm breakpoint is 640px

    // Set the initial value based on the current viewport
    setIsMobile(mediaQuery.matches);

    // Define a function to handle media query changes
    const handleViewportChange = (e) => {
      setIsMobile(e.matches); // Update the state when the viewport changes
    };

    // Add the event listener
    mediaQuery.addListener(handleViewportChange);

    // Cleanup the event listener on component unmount
    return () => {
      mediaQuery.removeListener(handleViewportChange);
    };
  }, []);

  /**
   * Need to re-configure the arrows so users can go back and play previous days.
   * Potentially need to set a limit to the number of previous days a user can play in one go?
   */

  //   const handleArrowClickRight = () => {
  //   const newDate = dayjs(date).add(1, 'day').format('YYYY-MM-DD');
  //   handleArrowClick(newDate)
  // }

  // // Function to handle the click and update the date
  // const handleArrowClick = (newDate) => {
  //   setVisibleIndexes([])
  //   setShowCorrectAttemptMessage(false);
  //   setDate(newDate);

  //   axios.get('http://127.0.0.1:3500/whatballer', { params: {
  //     userId: localStorage.getItem('whatballer-uuid'), 
  //     difficulty,
  //     date: newDate,
  //   } })
  //     .then((response) => {
  //       setPlayerData(response.data); // Set data from response
  //       setInputCharacters(response.data.data.nameResponse); // Set inputs from response

  //       return axios.get('http://127.0.0.1:3500/whatballer/attempt', { params: {
  //         userId: localStorage.getItem('whatballer-uuid'), 
  //         difficulty,
  //         date: newDate,
  //       } })
  //         .then((response) => {
  //           if (response.data.results?.length) {
  //             setInputCharacters(response.data.results[0].attempt); // Set inputs from response
  //           }

  //           if (response.data.results?.length) {
  //             setAttempts(response.data.results); // Set inputs from response
  //           }

  //           if (response?.data?.correctAttempt) {
  //             handleCorrectAnswer()
  //           }

  //           if (!response?.data?.correctAttempt && inputRefs.current[0]) {
  //             inputRefs.current[0].focus(); // Focus on the first input field after mount
  //           }

  //           const timeouts = [];

  //           for (let i = 0; i < 35; i++) {
  //             const timeout = setTimeout(() => {
  //               setVisibleIndexes((prevIndexes) => [...prevIndexes, i]);
  //             }, i * 50); // Delay each input's appearance by 500ms
  //             timeouts.push(timeout);
  //           }
        
  //           // Cleanup timeouts on component unmount
  //           return () => {
  //             timeouts.forEach((timeout) => clearTimeout(timeout));
  //           };
  //         })
  //     })
  //     .catch((err) => {
  //       setError(err.message); // Handle error if any
  //       console.error("Error fetching data:", err);
  //     });
  // };

  return (
      <div className="flex flex-col items-center bg-gradient-to-bl from-gray-900 via-slate-800 to-gray-900 min-h-screen">
      <Header
        isCorrect={isCorrect}
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        getWhatballerT={getWhatballerT}
        handleShare={handleShare}
      />
        {/* // We could shimmer the hints
        // Put the attempt counter somewhere (right or left) */}
      <main className="flex flex-col items-center flex-grow mt-14 w-12/12 md:w-9/12 xl:w-8/12 2xl:w-6/12">
      {/* // get width and divide it by the number of chars, then set the width of the input to that value */}
        <InputCharacters 
          inputCharacters={inputCharacters}
          handleObfuscation={handleObfuscation}
          inputRefs={inputRefs}
          visibleIndexes={visibleIndexes}
          setInputCharacters={setInputCharacters}
          handleCorrectAnswer={handleCorrectAnswer}
          setError={setError}
          date={date}
          difficulty={difficulty}
          setAttempts={setAttempts}
          setFirstLetterUsed={setFirstLetterUsed}
          setRevealed={setRevealed}
          setIsLoggedIn={setIsLoggedIn}
        />
        <hr className="w-4/5 border-t-1 border-slate-400 opacity-30 mx-auto my-4" />
        {isCorrect && !revealed && (
          <p className="glow mt-0 text-slate-200 font-panton text-center w-11/12 mb-2" style={{ textShadow: '0.5px 0.5px 1px #73ac5e'}}>You got it in {attempts.length}. Come back tomorrow</p> 
        )}
        {!isCorrect && !revealed && (attempts?.length ? attempts?.length < 1 : true) &&
          <p className="glow mt-0 text-slate-200 font-panton text-center w-11/12 mb-2" style={{ textShadow: '0.5px 0.5px 1px #73ac5e'}}>Guess the former Premier League footballer </p> 
        }
        {revealed &&
          <p className="glow mt-0 text-slate-200 font-panton text-center w-11/12 mb-2" style={{ textShadow: '0.5px 0.5px 1px #73ac5e' }}>You didn't get it today. Come back tomorrow.</p>
        }

        {/* // MAke the landing page have the right colours. Make it check that the number of players is reached and a waitlist is necessary. */}

        {/* Add buffer / waitlist for the app */}

        {attempts?.length > 0 && (
          <div> {
            attempts
              .filter(({ attempt }) => attempt.some(char => !char.correct))
              .map(({ attempt }) => (
              <div className="flex flex-wrap justify-center m-1">
                {attempt && attempt.map((char, index) => (
                char.value !== wordSpaceCharacter ? (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    value={char.value === '_' ? '' : char.value}
                    className={`
                      w-5
                      h-5
                      mx-0.5
                      sm:mx-1
                      text-center 
                      text-xs
                      text-slate-300
                      ${typeof char === 'object' &&
                          (char?.correct === true || char.reveal) ? 
                            `transition-colors duration-100 ${
                              char.reveal ?
                                'bg-orange-900' :
                              char.hint ?
                                'bg-blue-800' :
                                'bg-green-900'
                        }` : 
                        'bg-slate-700'}
                      border-gray-600 
                      border-r-emerald-50 
                      border-b-2 
                      rounded-md
                      focus:outline-none 
                      focus:border-gold-500
                    `}
                  />
                  
                ) : (
                <div key={index} className="h-5 w-5 mx-0.5 sm:mx-1"></div>
                )
              ))
                }
                
              </div>
            )
          )}
        </div>
        )}

        {/* <div className="w-12/12 md:w-9/12 xl:w-8/12 2xl:w-6/12">
          <div className="flex justify-left w-full">
            <img 
              src={'./arrow-left-svgrepo-com.svg'} 
              alt={`Left Arrow`} 
              className="opacity-90 mt-2 h-5 w-5 border-yellow-100 border-opacity-5 rounded-lg hover:bg-yellow-300 hover:bg-opacity-5 cursor-pointer"
              // onClick={handleArrowClickRight}
            />
          </div>
        </div> */}

        {error ? (
          // Replace with loading spinner
          <p>Error: {error}</p>
        ) : playerData ? (
            <div className={`
              w-11/12
              font-astonpoliz
            text-gray-950 
              mt-2
              p-0
              sm:p-8
              text-m 
              bg-yellow-400 
              bg-opacity-90 
              border-yellow-200 
              sheen
              border-2 
              rounded-lg 
              animate
            `} style={{ textShadow: '0.5px 0.5px 1px #73ac5e'}}>
              {!isMobile ? (
                <div className="grid grid-cols-4">
                  <div className="px-1 py-1"></div>
                  <div className="px-1 py-1"></div>
                  <div className="px-1 py-1">APPS</div>
                  <div className="px-1 py-1">GOALS</div>
                </div>
              ) : (
                <div className="grid grid-cols-12">
                  <div className="px-1 py-1 col-span-3"></div>
                  <div className="px-1 py-1 col-span-6"></div>
                  <div className="px-1 py-1 col-span-3">A (G)</div>
                </div>
              )}

            {playerData.data.stints.map((stint, index) => (
                !isMobile ? 
                  <div key={index} className="grid grid-cols-4 hover:bg-gray-100">
                    <div className="px-1 py-1">{stint[0]?.value}</div>
                    <div className="px-1 py-1">{stint[1]?.value || '?'}</div>
                    <div className="px-1 py-1">{stint[2]?.value || '?'}</div>
                    <div className="px-1 py-1">{stint[3]?.value || '?'}</div>
                  </div>
                : (
                  <div key={index} className="grid grid-cols-12 hover:bg-gray-100">
                    <div className="px-1 py-1 col-span-3">{handleDateFormatting(stint[0]?.value) || '?'}</div>
                    
                    <div className="px-1 py-1 col-span-6">{stint[1]?.value || '?'}</div>
                    <div className="px-1 py-1 col-span-3">{stint[2]?.value || '?'} ({stint[3]?.value || '?'})</div>
                  </div>
                )
            ))}

            {!isMobile ? 
                   (
                    <div className="grid grid-cols-4 mt-4">
                      <div className="px-1 py-1">Total</div>
                      <div className="px-1 py-1"></div>
                      <div className="px-1 py-1">{playerData.data.totalAppearances}</div>
                      <div className="px-1 py-1">{playerData.data.totalGoals}</div>
                    </div>
                   )
                : (
                  <div className="grid grid-cols-12 mt-4">
                    <div className="px-1 py-1 col-span-3">Total</div>
                    <div className="px-1 py-1 col-span-6"></div>
                    <div className="px-1 py-1 col-span-3">{playerData.data.totalAppearances} ({playerData.data.totalGoals})</div>
                  </div>
                )
              }

            <div className="grid grid-cols-4 mt-4">
              <div className="px-1 py-1">Hint Available:</div>
              <div className="px-1 py-1"></div>
              {hintsAvailable && hintsAvailable.map(hint => (
                <div className="px-1 py-1 col-span-2">
                  <div 
                    className={`
                      text-slate-900 
                      rounded 
                      transition-opacity 
                      text-center 
                      ${firstLetterUsed || isCorrect ? `
                        line-through
                        bg-slate-500 
                        opacity-40 
                      ` : `
                        bg-slate-300 
                        hover:bg-slate-500 
                        hover:opacity-40
                        opacity-40 
                        bg-opacity-30 
                        cursor-pointer 
                      `}
                    `}
                    onClick={(e) => handleHintClick(e.target)}
                  >
                    {hint.type.toUpperCase()}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p>Loading data...</p>
        )}
        {firstLetterUsed && !isCorrect && <div className="flex w-11/12 mt-4">
        
          <div
            className="
              bg-yellow-300
              font-panton
              cursor-pointer
              text-slate-300
              font-bold
              py-0.5
              px-0.5
              m-auto
              text-sm
              rounded-lg
              w-3/12
              sm:w-2/12
              text-center
              bg-opacity-10
              hover:bg-yellow-100
              hover:bg-opacity-10
            "
            onClick={handleReveal}
          >
            Reveal
          </div>
        </div>}
      </main>

      <footer className="w-full py-4 text-center">
        <a href="https://www.producthunt.com/posts/whatballer?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-whatballer" target="_blank">
          <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=623654&theme=neutral" alt="Whatballer - Guess&#0032;the&#0032;Premier&#0032;League&#0032;footballer&#0044;&#0032;daily&#0046; | Product Hunt" width="160" className="m-auto p-auto"/>
        </a>
        <div className="bg-slate-100 opacity-50 text-slate-800 p-1 text-xs rounded mt-3 transition-opacity duration-500 input-fade w-10/12 sm:w-4/12 justify-center p-auto m-auto">
          You're using an app that is in beta. Please report any bugs to whatballer@gmail.com.
        </div>
        <p className="text-xs text-gray-300 mt-2">All rights reserved. Whatballer 2024</p>
      </footer>
    </div>
  );
}

export default App;
