import axios from 'axios';
import { getHeaders } from './whatballer.http';
import { backendUrl } from '../constants/http.constants';

export const verifyGoogleAccessTokenAndGetSessionToken = async (token) => {
  try {
    const res = await axios.get(`${backendUrl}/auth/google/callback?token=${token}`);

    return res;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const apiStatus = async (token) => {
  try {
    const res = await axios.get(`${backendUrl}/auth/api/status`, {
      headers: getHeaders(token),
    });

    return res;
  } catch (e) {
    throw new Error(e.message);
  }
};
