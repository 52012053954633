// https://react-oauth.vercel.app/
import { useGoogleLogin } from '@react-oauth/google';
import { verifyGoogleAccessTokenAndGetSessionToken } from './http/Login';
import { removeLocalToken, setLocalToken } from './local/token.local';
import { GoSignOut } from "react-icons/go";

const GoogleLoginContainer = ({ isLoggedIn, setIsLoggedIn, getWhatballerT }) => {
  /**
   * Seems that the image doiesnt load well after login
   */
  // const [userProfilePic, setUserProfilePic] = useState(
    // <GoSignOut />
    // <img
    //   src='https://lh3.googleusercontent.com/a/ACg8ocJp5vQhdO49f5FBGtNeHRpbAbhXsU2qatJ8b_2KUY5iU1SfyeE=s96-c'
    //   height="31"
    //   width="31"
    //   className="rounded-2xl filter grayscale"
    // />
  // )

  const getGoogleUserData =
    async (accessToken) => {
      try {
        const r = await verifyGoogleAccessTokenAndGetSessionToken(accessToken);

        setLocalToken(r.data.jwtToken)
        setIsLoggedIn(true);
        getWhatballerT()

      } catch (e) {
        console.error(e)
      }
    };
  
  const login = useGoogleLogin({
    onSuccess: tokenResponse => getGoogleUserData(tokenResponse.access_token),
  });

  const logout = () => {
    removeLocalToken();
    setIsLoggedIn(false);
    getWhatballerT();
  }

  return (
    <div>
      {isLoggedIn ? (
        <div className="cursor-pointer text-xl" onClick={() => logout()}><GoSignOut /></div>
      ) : (
          <button
            // could make less border
            className="font-panton font-bold text-sm flex items-center justify-center bg-green-700 border border-gray-300 text-slate-100 rounded-md px-2 py-0.5 shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => login()}
          >
            Sign In
            <img src="https://developers.google.com/identity/images/g-logo.png" alt="Google logo" class="h-4 w-4 ml-1 rounded-2xl" />
          </button>
      )}
    </div>
  );
};

export default GoogleLoginContainer;
